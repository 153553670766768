import React from 'react';

export default function () {
    return <section>
        <article className="w-60">
            <h2>System Operation</h2>

            <p>Airflow through the CAF FPS55.2 Series system is as follows:</p>
            <p>Contaminated air is drawn into the system through the pre-cleaner. The circular motion of the pre-cleaner separates out larger particulates, dramatically increasing filter life.</p>
            <p>2. Air passes through the 3 stage primary filter.</p>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <p><strong style={{ color: 'red' }}>Stage 1</strong> <em>Paper Media</em> particulate filter. The standard for the heavy dust environment of agricultural, industrial, mining, and off-road conditions. In addition, paper is superior in absorbing liquid droplets that are present in spray aerosols.</p>
                </li>
                <li>
                    <p><strong style={{ color: 'red' }}>Stage 2</strong> is a respirator-grade <em style={{ textTransform: `uppercase` }}>Activated Carbon</em>.  The porous nature of this media effectively adsorbs organic vapors, such as diesel and chemical fumes. CAF’s customized, refined activated carbon works on surface area, and our patented design maximizes this principle. Specialized carbons are available for specific vapors.</p>
                </li>
                <li>
                    <p><strong style={{ color: 'red' }}>Stage 3</strong> is a <em>sub-micron <span style={{ textTransform: `uppercase` }}>Final Filter</span></em> that provides additional protection while allowing maximum airflow.</p>
                </li>
            </ul>
        </article>
    </section>;
}